@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Default theme variables */
  /* --theme-primary: #2563eb;
  --theme-secondary: #4f46e5;
  --theme-background: #ffffff;
  --theme-text: #1f2937;
  --theme-accent: #3b82f6;
  --background: #ffffff;
  --foreground: #171717; */
}

/* Theme transition styles */
*, *::before, *::after {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Layout classes */
/* .has-header {
  padding-top: 60px;
}

.has-sidebar {
  padding-left: 200px;
}

.has-footer {
  padding-bottom: 60px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}

.dark {
  --theme-background: #111827;
  --theme-text: #f9fafb;
} */
